var compareVersions = require('compare-versions');

function compare(browserVersion, browserCompatibleVersion) {
  var outcome = 0
  //outcomes:
  //0 - outcome is unchanged, failed to produce an outcome
  //1 - browser type is acceptable, browser version is acceptable
  //2 - browser type is acceptable, browser version is out of date
  //3 - browser type is acceptable, failed to detect browser version
  //4 - browser type is not acceptable

  if(browserCompatibleVersion){
    try{
      if(compareVersions(browserVersion, browserCompatibleVersion) < 0){
        outcome = 2
      } else {
        outcome = 1
      }
    }
    catch(e){
      try{
        if(compareVersions(browserVersion.replace('-',''), browserCompatibleVersion.replace('-','')) < 0){
          outcome = 2
        } else {
          outcome = 1
        }
      }
      catch(ee){
        //TODO ideally we should log result.browser if the user ends up here
        outcome = 3
      }
    }
  } else {
    outcome = 4
  }
  return outcome
}

module.exports = compare
