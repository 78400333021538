var browserslist = require('./browserslist.js').default;
var UAParser = require('ua-parser-js');
var parser = new UAParser(navigator.userAgent);
var result = parser.getResult();
var compare = require('./compare.js');

var browserslistNameMappings = {
  "Chrome for Android": "and_chr",
  "IE": "explorer",
  "UCBrowser": "and_uc",
  "Chrome": "chrome",
  "Edge": "edge",
  "Firefox": "firefox",
  "Mobile Safari": "ios_saf",
  "Opera": "opera",
  "Safari": "safari",
  "Samsung Browser": "samsung"
};

var browserName = browserslistNameMappings[result.browser.name];
var browserCompatibleVersion = browserslist[browserName];
var browserVersion = result.browser.version;

var outcome = compare(browserVersion, browserCompatibleVersion);
var redirectURL = 'https://bestvpn.org/outdatedbrowser/en';

switch (outcome){
  case 2: //outdated browser
  case 4: //invalid browser
    window.location = redirectURL;
    break;
}
